/**
 * This contains the points in time that the 445 calendar has to shift forward a week in order to account for
 * the week that is lost every 5-7 years.  This happens because a year is not exactly 52 weeks long.
 */
const FOUR_FOUR_FIVE_SHIFT_OFFSETS: number[] = []
const EPOCH_DATE = new Date('2018-01-06');
const EPOCH_MILLIS = EPOCH_DATE.getTime();
const WEEK_IN_MILLIS = 6048e5;

export class TimePeriod {

    private readonly epochOffset: number;
    private readonly yyyymmdd: string;

    /**
     *
     * @param value Either string in the format YYYYMMDD, a number representing an offset from the time period epoch of 2018-01-06 or another TimePeriod
     */
    constructor(value: TimePeriod | number | string) {

        if(value instanceof TimePeriod) {
            this.epochOffset = value.epochOffset;
            this.yyyymmdd = value.yyyymmdd;
        } else if(typeof value === 'string') {
            const asDate = TimePeriod.toGmtDate(value);
            this.epochOffset = (asDate.getTime() - EPOCH_MILLIS) / WEEK_IN_MILLIS;
            this.yyyymmdd = value;
        } else {
            this.yyyymmdd = new Date(EPOCH_MILLIS + value * WEEK_IN_MILLIS)
                .toISOString().slice(0, 10).replaceAll('-', '');
            this.epochOffset = value;
        }

        if(this.yyyymmdd.length !== 8 || this.epochOffset % 1 !== 0) {
            throw new Error('Invalid PeriodEndDate')
        }
    }

    add(periods: number): TimePeriod {
        return new TimePeriod(this.epochOffset + periods);
    }

    subtract(periods: number): TimePeriod {
        return new TimePeriod(this.epochOffset - periods);
    }

    diff(ped: TimePeriod): number;
    diff(yyyymmmdd: string): number;
    diff(pedOrString: TimePeriod | string): number {

        let ped: TimePeriod = pedOrString as TimePeriod;
        if(typeof pedOrString === 'string') {
            ped = new TimePeriod(pedOrString);
        }

        return this.valueOf() - ped.valueOf();
    }
    is445(): boolean {
        return [0,4,8].includes(this.epochOffset % 13);
    }

    is4455(): boolean {
        return this.epochOffset % 13 === 0;
    }

    isYearEnd(): boolean {
        return ['1228', '1229', '1230', '1231', '0101', '0102', '0103', '0104'].includes(this.yyyymmdd.slice(4))
    }
    isFullyCovered(periodType: 'week' | '4weeks' | 'month' | 'quarter' | 'year', earliestEpochOffset: number): boolean {
        const periodStartOffset = this.getStartOffset(periodType);
        return periodStartOffset >= earliestEpochOffset;
    }
    
    getStartOffset(periodType: 'week' | '4weeks' | 'month' | 'quarter' | 'year'): number {
        const endOffset = this.valueOf(); // This is an integer representing the number of weeks from EPOCH_DATE
    
        switch(periodType) {
            case 'week':
                // A week is always 1 week long
                return endOffset - 1;
    
            case '4weeks':
                // 4 weeks = 4 offsets
                return endOffset - 4;
    
            case 'month':
                // For a 4-4-5 calendar:
                // If this month is a "5-week month":
                if (TimePeriod.is4455(endOffset)) {
                    return endOffset - 5;
                } else if (TimePeriod.is445(endOffset)) {
                    // Otherwise it's a 4-week month
                    return endOffset - 4;
                } else {
                    // If not following a 4-4-5 pattern, you'd need logic for standard months.
                    // For standard Gregorian months:
                    //  - Convert end date to YYYY-MM, go to the first day of that month, 
                    //    and compute offset again. But for a pure 4-4-5 system, rely on offsets.
                    throw new Error("Not a valid month boundary in 4-4-5 calendar");
                }
    
            case 'quarter':
                // One quarter in 4-4-5 is 13 weeks
                return endOffset - 13;
    
            case 'year':
                // A 4-4-5 year usually has 52 weeks (some years may have 53)
                // If your system can have 53-week years, adjust accordingly.
                // For now, assume 52.
                return endOffset - 52;
    
            default:
                throw new Error("Unknown period type");
        }
    }

    calculateEpochOffset(date: Date): number {
        return (date.getTime() - EPOCH_MILLIS) / WEEK_IN_MILLIS;
    }

    valueOf(): number {
        return this.epochOffset;
    }

    toString(): string {
        return this.yyyymmdd;
    }

    toDateString(): string {
        const v = this.yyyymmdd;
        return `${v[4]}${v[5]}/${v[6]}${v[7]}/${v[0]}${v[1]}${v[2]}${v[3]}`
    }

    toLegacyString(): string {
        const v = this.yyyymmdd;
        return `${v[4]}${v[5]}-${v[6]}${v[7]}-${v[0]}${v[1]}${v[2]}${v[3]}`
    }

    toJSON(): any {
        return this.toString();
    }

    toGmtDate(): Date {
        return TimePeriod.toGmtDate(this.yyyymmdd);
    }

    getOffsetToNearestFourWeekStaticPeriod(): number {
        const targetDateMillis = EPOCH_MILLIS + Math.floor(this.epochOffset) * WEEK_IN_MILLIS;
        const periodDurationMillis = 4 * WEEK_IN_MILLIS ;
        const periodsSinceEpoch = Math.floor((targetDateMillis - EPOCH_MILLIS) / periodDurationMillis);
        const nearestPeriodStartMillis = EPOCH_MILLIS + periodsSinceEpoch * periodDurationMillis;
        return (nearestPeriodStartMillis - EPOCH_MILLIS) / WEEK_IN_MILLIS;
    }

    static is445(offset: number): boolean {
        return [0,4,8].includes(offset % 13)
    }

    static is4455(offset: number) {
        return offset % 13 === 0;
    }

    static get Latest(): TimePeriod {
        return new TimePeriod(Math.floor((Date.now() - EPOCH_MILLIS)/ WEEK_IN_MILLIS));
    }

    static fromLegacyString(legacyMmDdYyyy: string): TimePeriod {
        const v = legacyMmDdYyyy;
        return new TimePeriod(`${v[6]}${v[7]}${v[8]}${v[9]}${v[0]}${v[1]}${v[3]}${v[4]}`)
    }

    static toGmtDate(yyyymmdd: string): Date {
        const v = yyyymmdd;
        return new Date(`${v[0]}${v[1]}${v[2]}${v[3]}-${v[4]}${v[5]}-${v[6]}${v[7]}`)
    }

    static epoch: Date = EPOCH_DATE;
}

Math.PI
